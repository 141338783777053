import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

const PageTitleHome = () => {
    const [hero, setHero] = useState()
    const getHeroData = (maxRetries = 3, retryDelay = 50) => {
        let retryCount = 0;

        const fetchData = () => {
            fetch("https://strapi-production-0db1.up.railway.app/api/hero?populate=*")
                .then((response) => response.json())
                .then((jsonData) => {
                    setHero(jsonData.data.attributes);
                })
                .catch((error) => {
                    console.error(error);
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            fetchData();
                        }, retryDelay);
                        retryCount++;
                    }
                });
        };

        fetchData();
    };

    useEffect(() => { getHeroData() }, [])


    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="row">
                    <div className="col col-1 position-relative">
                        <div className="title">
                            <h1 className="h">{hero?.title}<span className="dot">.</span></h1>

                            <div className="title-clone">Hola</div>
                        </div>

                        <div className="spacer p-top-lg">
                            <p className="p-large w-75">{hero?.description}</p>
                        </div>
                    </div>

                    <div className="col col-2 d-none d-sm-block">
                        <div className="d-flex">
                            <div className="align-self-start w-100">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src={hero?.portrait?.data?.attributes?.url} className="img-fluid" alt="Luca Reynolds" />
                                    </div>
                                </div>
                            </div>

                            <div className="align-self-end p-left-scroll">
                                <Link to="my-works"
                                    title="Scroll"
                                    spy={true}
                                    smooth={true}
                                    duration={900}
                                    className="btn btn-link btn-vertical transform-scale-h border-0 p-0 scroll-to-id d-flex"
                                    href="#my-works"
                                >
                                    <span className="vertical-text">
                                        <span>B</span>
                                        <span>a</span>
                                        <span>j</span>
                                        <span>a</span>
                                        <span>r</span>
                                    </span>

                                    <span className="align-self-end">
                                        <i className="icon-c icon-arrow-down" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleHome;
