import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
const AboutContent = () => {

    const [contact, setContact] = useState()
    const getHeroData = (maxRetries = 3, retryDelay = 50) => {
        let retryCount = 0;

        const fetchData = () => {
            fetch("https://strapi-production-0db1.up.railway.app/api/hero?populate=*")
                .then((response) => response.json())
                .then((jsonData) => {
                    setContact(jsonData?.data?.attributes);
                })
                .catch((error) => {
                    console.error(error);
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            fetchData();
                        }, retryDelay);
                        retryCount++;
                    }
                });
        };

        fetchData();
    };


    useEffect(() => {
        getHeroData()
    }, [])


    return (
        <div id="about" className="block">
            {/* <h2>
                <span className="line">A passionate</span><br /> photographer, designer in media.
            </h2> */}

            <div className="row bg-half-ring-left gutter-width-lg">
                <div className="col align-self-top pl-0">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src={contact?.portrait?.data?.attributes?.url} alt="Luca Reynolds" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="col align-self-center description">
                    <h4>{contact?.title}</h4>
                    <ReactMarkdown>{contact?.longDescription}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
