import React, { useEffect, useState, Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';
import ContactForm from '../components/form/ContactForm';

const Contacts = () => {
    document.body.classList.add('page');
    document.body.classList.add('bg-fixed');
    document.body.classList.add('bg-line');


    const [contact, setContact] = useState()
    const getContactData = (maxRetries = 3, retryDelay = 50) => {
        let retryCount = 0;

        const fetchData = () => {
            fetch("https://strapi-production-0db1.up.railway.app/api/contact?populate=*")
                .then((response) => response.json())
                .then((jsonData) => {
                    setContact(jsonData.data.attributes);
                })
                .catch((error) => {
                    console.error(error);
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            fetchData();
                        }, retryDelay);
                        retryCount++;
                    }
                });
        };

        fetchData();
    };

    useEffect(() => { getContactData() }, [])


    const { country, email, phone, state } = contact || {}
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Contáctame | Yari Mitsuko</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleContacts />

                <section id="page-content" className="block spacer p-top-xl">
                    <div className="wrapper">
                        <div id="contacts" className="block">
                            <div className="row">
                                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                    <h2><span className="line">Contactame</span></h2>

                                    <p className="mt-0">{description}</p>
                                </div> */}

                                <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                                    <div className="list-group list-group-horizontal-sm">
                                        <div className="list-group-item">
                                            <h4>País</h4>

                                            <p>{country}</p>
                                        </div>

                                        <div className="list-group-item">
                                            <h4>Estado</h4>

                                            <p>{state}</p>
                                        </div>

                                        <div className="list-group-item">
                                            <h4>Numero de teléfono</h4>

                                            <p><a title={phone} href={`tel:${phone}`}>{phone}</a></p>
                                        </div>
                                    </div>

                                    <div className="list-group list-group-horizontal-sm mt-0">
                                        <div className="list-group-item mb-0 pb-0">
                                            <h4>Email</h4>

                                            <p><a title="hello@yourdomain.com" href={`mailto:${email}`}>{email}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="block spacer p-top-xl">
                            <h2>Rellena el  <span className="line">formulario.</span></h2>

                            <ContactForm />
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
