import React, { Fragment, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useParams } from 'react-router-dom';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import ReactMarkdown from 'react-markdown'

const WorksInside = () => {

    const [work, setWork] = useState([])
    const { id } = useParams();

    const getImages = (maxRetries = 3, retryDelay = 50) => {
        const fetchData = (retryCount = 0) => {
            fetch(`https://strapi-production-0db1.up.railway.app/api/blueprints/${id}?populate=*`)
                .then((response) => response.json())
                .then((jsonData) => {
                    setWork(jsonData?.data?.attributes)
                })
                .catch((error) => {
                    console.error(error);
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            fetchData(retryCount + 1);
                        }, retryDelay);
                    }
                });
        };
        fetchData();
    };


    useEffect(() => {
        getImages()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    document.body.classList.add('single');
    document.body.classList.add('single-portfolio');
    document.body.classList.add('bg-fixed');
    document.body.classList.add('bg-line');
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Trabajos | Yari Mitsuko</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
                <section id="page-content">
                    <div className="wrapper">
                        <div id="single">
                            <div className="row gutter-width-lg single-content">
                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                    {work?.photos?.data.map((value, index) => {
                                        const { url, formats } = value?.attributes
                                        const { large, medium, small, thumbnail } = formats


                                        return <div key={index} className="img object-fit">
                                            <div className="object-fit-cover">
                                                {
                                                    url && <img
                                                        src={thumbnail?.url}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = medium?.url;
                                                        }}
                                                        alt="Product photography"
                                                        srcSet={`${small.url} 480w, ${medium?.url} 800w, ${large?.url} 1200w`}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>


                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                    <p className="date">
                                        <span className="large">{work?.stratDate}</span>
                                        {/* <span className="large">25</span> May, 2020 */}
                                    </p>

                                    <h1 className="small">{work?.title}</h1>
                                    {/* <h1 className="small">Product photography</h1> */}

                                    <div className="description">
                                        <ReactMarkdown>{work?.description}</ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <Footer /> */}
        </Fragment>
    );
};

export default WorksInside;
