import React, { Component } from 'react';
import Isotope from 'isotope-layout';
import ImagesLoaded from 'imagesloaded';
import PropTypes from 'prop-types';
import GalleryMenuData from '../../data/gallery/galleryMenu';
// import GalleryItemsData from '../../data/gallery/galleryItems.json'
import { Link } from 'react-router-dom';

class Gallery extends Component {

    constructor(props) {
        super(props);
        this.onFilterChange = this.onFilterChange.bind(this);

        this.state = {
            selected: 0,
            list: GalleryMenuData,
            projects: [],
            constructionTypes: []
        };
    }

    handleClick(i, e) {
        e.preventDefault();

        this.setState({
            selected: i
        });

        return false
    }

    onFilterChange = (newFilter) => {
        var gallery_items_name = this.grid;
        var gallery_item_name = '.gallery-item';
        if (this.iso === undefined) {
            this.iso = new Isotope(gallery_items_name, {
                itemSelector: gallery_item_name,
                masonry: {
                    horizontalOrder: true
                }
            });
        }
        if (newFilter === '*') {
            this.iso.arrange({ filter: `*` });

        } else {
            this.iso.arrange({ filter: `.${newFilter}` });
        }
    };

    transformConstructionType = (types) => {
        let uniqueTypes = [...new Set(types)];

        const formatedTypes = uniqueTypes.map((value, key) => {
            return { id: key + 1, title: value, filter: value }
        })
        formatedTypes.unshift({ "id": 0, "title": "All", "filter": "*" })
        this.setState({ constructionTypes: formatedTypes })
    }

    getImages = (maxRetries = 3, retryDelay = 50) => {
        const fetchData = (retryCount = 0) => {
            fetch("https://strapi-production-0db1.up.railway.app/api/blueprints/?populate=*")
                .then((response) => response.json())
                .then((jsonData) => {
                    const ConstructionType = jsonData?.data.map((data) => {
                        return data?.attributes?.construction_types?.data.map((type) => {
                            return type?.attributes?.name;
                        });
                    }).flat();
                    this.transformConstructionType(ConstructionType);
                    this.setState({ projects: jsonData.data });
                })
                .catch((error) => {
                    console.error(error);
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            fetchData(retryCount + 1);
                        }, retryDelay);
                    }
                });
        };

        fetchData();
    };

    getConstructionsTypesString = (list) => {
        const types = list.map((element) => {
            return element.attributes.name
        })
        return types.join(" ")
    }

    componentDidMount() {
        var gallery_items_name = this.grid;
        var gallery_item_name = '.gallery-item';

        var iso = new Isotope(gallery_items_name, {
            itemSelector: gallery_item_name,
            masonry: {
                horizontalOrder: true
            }
        });

        var imgLoad = new ImagesLoaded(gallery_items_name);

        imgLoad.on('progress', function (instance, image) {
            iso.layout();
        });
        this.getImages()
    }


    render() {
        const listCount = this.state.constructionTypes.length;
        return (
            <div className="gallery" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="wrapper">
                    <ul className="gallery-filter list-unstyled no-space">
                        {this.state.constructionTypes.map((item, key) => (
                            <React.Fragment key={key}>
                                <li>
                                    <a href="#my-works"
                                        title={item.title}
                                        className={"btn btn-link transform-scale-h click" + (key === this.state.selected ? ' active' : '')}
                                        data-filter={item.filter}
                                        onClick={(event) => {
                                            this.onFilterChange(item.filter);
                                            this.handleClick(key, event)
                                        }}>
                                        {item.title}
                                    </a>
                                </li>

                                {key !== listCount ?
                                    <li>
                                        <span className="btn btn-link">-</span>
                                    </li>
                                    : ""
                                }
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
                <div className={"gallery-item-wrapper" + this.props.paddingBottomClass} >
                    <div className="gallery-items" ref={(c) => this.grid = c}>
                        {this.state.projects && this.state.projects.map((item, key) => {
                            const { title, cover } = item?.attributes || {}
                            const { url, formats } = cover?.data?.attributes || ''
                            const { large, medium, small, thumbnail } = formats
                            const type = this.getConstructionsTypesString(item?.attributes.construction_types?.data)
                            return (
                                <Link key={key} title={title} className={"gallery-item active " + `gallery-item-visible ${type}`} to={{
                                    pathname: process.env.PUBLIC_URL + `works-inside/${item?.id}`,
                                    state: item
                                }}  >
                                    <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            {url && <img
                                                src={thumbnail?.url}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = medium?.url;
                                                }}
                                                srcSet={`${small.url} 480w, ${medium?.url} 800w, ${large?.url} 1200w`} alt={title}
                                            />}
                                        </div>
                                    </div>

                                    <div className="gallery-hover">
                                        <div className="gallery-hover-wrapper">
                                            <h3>{title}</h3>

                                            <span className="btn btn-link border-0 transform-scale-h p-0">
                                                {item.button}
                                                <i className="icon-c icon-arrow-right" />
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>

                {this.props.seeMore ?
                    <div className="text-center no-space">
                        <a title="See more" className="btn btn-link border-0 p-0 transform-scale-h" href={process.env.PUBLIC_URL + "/works"}>Ver más<i className="icon-c icon-arrow-right" /></a>
                    </div>
                    : ""
                }
            </div>
        );
    }
}

Gallery.propTypes = {
    seeMore: PropTypes.string,
    paddingBottomClass: PropTypes.string
};

export default Gallery
