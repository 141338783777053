import React from 'react';

const Contacts = () => {
    return (
        <section id="contact" className="block spacer p-top-xl mb-5">
            <div className="wrapper">
                <div className="text-center">
                    <h3 className="mb-0 after">"¿Te gusta mi trabajo? ¿Quieres tener diseños arquitectónicos de calidad?
                        <br />¡Ponte en contacto conmigo ahora mismo!"
                    </h3>

                    <div className="block spacer p-top-lg">
                        <a title="Contact" href={process.env.PUBLIC_URL + "/contacts"} className="btn btn-dark btn-bold transform-scale-h border-0">Contáctame</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contacts;
