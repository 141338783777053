import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'

// import ExperienceItemsData from '../../data/experience/experience';


const MyExperience = () => {


    const [about, setAbout] = useState()

    const getAbout = (maxRetries = 3, retryDelay = 50) => {
        let retryCount = 0;

        const fetchData = () => {
            fetch("https://strapi-production-0db1.up.railway.app/api/abouts?populate=*")
                .then((response) => response.json())
                .then((jsonData) => {
                    setAbout(jsonData?.data);
                })
                .catch((error) => {
                    console.error(error);
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            fetchData();
                        }, retryDelay);
                        retryCount++;
                    }
                });
        };

        fetchData();
    };

    useEffect(() => {
        getAbout()
    }, [])

    console.log(about)
    return (
        <div id="my-experience" className="block spacer p-top-xl">
            <h2>My <span className="line">experience.</span></h2>

            <div className="row gutter-width-lg with-pb-lg">
                {about && about.map((item, key) => {
                    console.log(item)
                    const { title, endDate, startDate, description } = item?.attributes
                    return (
                        <div key={key} className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="card border-0">
                                <div className="card-body p-0">
                                    <h4>{title}</h4>

                                    <p className="p-small text-secondary bold">{startDate}/{endDate}</p>

                                    <ReactMarkdown>{description}</ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyExperience;
